import React, { useState, useEffect } from "react";
import './SideBar.css';
import { SideBarData } from './SideBarData';
import { thisDay } from '../Pages/Main';
import { format } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { fetchCoachTeamMembers } from '../API/FetchAllDetailsAPI';

const Logout = (val) => {
  localStorage.removeItem('user');
  localStorage.removeItem('filteredPerson');
  window.location = val.link;
};


const SideBar = () => {
  const [isExpanded, setExpandState] = useState(false);
  const [hasTeamMembers, setHasTeamMembers] = useState(false);

  useEffect(() => {
    async function fetchTeamMembers() {
      const userName = localStorage.getItem('user'); // Assuming user name is stored in localStorage
      if (userName) {
        const teamMembers = await fetchCoachTeamMembers(userName);
        setHasTeamMembers(teamMembers.length > 1);
      }
    }
    fetchTeamMembers();
  }, []);

  // Filter sidebar data based on team member condition
  const filteredSideBarData = SideBarData.filter(item => item.title !== 'Inactive Members' || hasTeamMembers);

  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="nav-upper">
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <h2>Wellness</h2>
            </div>
          )}
          <button
            className={
              isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
            }
            onClick={() => setExpandState(!isExpanded)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="nav-menu">
          {isExpanded && (
            <span>
              <br></br>
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <DateRangeIcon style={{ marginLeft: 35, color: 'white', marginTop: 6 }} />
                <h3 className={`normal-label`} style={{ color: 'white', fontSize: 14, textTransform: 'uppercase', letterSpacing: 0, marginLeft: 7 }}>
                  {format(new Date(thisDay), "dd-MMM-yy")}
                  <br></br>V: 3.3.5
                </h3>
              </span>
            </span>
          )}
          <ul className='sidebarlist' style={ isExpanded ? {width: 225} : {width: 60}}>
            {filteredSideBarData.map((val, key) => (
              <li key={key} className='row' onClick={() => { val.title !== 'Logout' ? window.location = val.link : Logout(val) }}>
              {isExpanded && <div id='title'>{val.title}</div>}
              <div id='icon' >{val.icon}</div>
              </li>
            ))}
          </ul>
          {!isExpanded && (
          <div style={{ marginTop: '145px', marginLeft: '5px',color: 'white',fontWeight: 'bold',fontFamily:'poppins' }}>
            <br></br>V: 3.3.5
          </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default SideBar;